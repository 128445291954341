import React from "react"

import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

export default function(props) {

  return (
      <section id={props.id} className={`${styles.txt}${props.className ? ' ' + props.className : ''}`}>
        <ContentContainer>
          {props.children && <div className={styles.container}>{props.children}</div>}
          
          {props.html &&
            <div className={styles.container} dangerouslySetInnerHTML={{__html: props.html}} />
          }
        </ContentContainer>
      </section>
  );
}
