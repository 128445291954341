import React from "react"

import Layout from '../layouts/default'

import TextFormated from '../content-elements/text-formated'

export default () => (
    <Layout title="404" locale="de">
      <TextFormated>
        <p>Seite nicht gefunden - Page not found - Az oldal nem található - Stránka nenájdená - Strona nie znaleziona</p>
      </TextFormated>
    </Layout>
)
